import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./home.component";
import { HomeRoutingModule } from "./home-routing.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MaterialModule } from "../material.module";
import { OrgInsuranceComponent } from "./org-insurance/org-insurance.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { MessageComponent } from "./message/message.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { AllconsultComponent } from "./allconsult/allconsult.component";
import { TermConditionComponent } from "./term-condition/term-condition.component";
import { ConfirmModalComponent } from "./term-condition/confirm-modal/confirm-modal.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { BreadcrumbComponent, BreadcrumbItemDirective } from "xng-breadcrumb";
import { RouterModule } from "@angular/router";

import { DigitalAssetsComponent } from "./digital-assets/digital-assets.component";
import { CrudResourceComponent } from "./digital-assets/crud-resource/crud-resource.component";
import { CrudIncentiveComponent } from "./digital-assets/crud-incentive/crud-incentive.component";

import { QuillModule } from "ngx-quill";

import { EditorComponent } from "@tinymce/tinymce-angular";

@NgModule({
  declarations: [
    HomeComponent,
    MessageComponent,
    ChangePasswordComponent,
    TermConditionComponent,
    ConfirmModalComponent,
  ],
  imports: [
    HomeRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AngularEditorModule,
    SharedModule,
    TranslateModule,
    MaterialModule,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    RouterModule,
    QuillModule.forRoot(),
    EditorComponent,
  ],
  providers: [],
  exports: [
    HomeComponent,
    HomeRoutingModule,
    MessageComponent,
    ChangePasswordComponent,
    TermConditionComponent,
    ConfirmModalComponent,
  ],
})
export class HomeModule {}
