import { Injectable } from "@angular/core";
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { LoaderService } from "./loader.service";
import { Observable, defer, EMPTY, throwError } from "rxjs";
import { tap, catchError, finalize, filter } from "rxjs/operators";

@Injectable()
export class LoaderInterceptorService implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService) {}

  private removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(req);

    this.loaderService.isLoading.next(true);

    return defer(() => {
      return next.handle(req).pipe(
        filter(event => event instanceof HttpResponse),
        tap(() => {
          this.removeRequest(req);
        }),
        catchError((error) => {
          this.removeRequest(req);
          return throwError(() => {
            return error;
          });
        }),
        finalize(() => {
          this.removeRequest(req);
        })
      );
    });
  }
}