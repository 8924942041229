<div>
  <ol>
    @for (msg of data; track msg) {
      <li class="data">
        <img src="../../../assets/images/close.svg" style="margin-right: 2%" />{{
        msg
        }}
      </li>
    }
  </ol>
  <!-- <div class="dismiss">
  <button mat-icon-button (click)="snackBarRef.dismiss()">
    <mat-icon>close</mat-icon>
  </button>
</div> -->
</div>
