<mat-toolbar>
  <mat-toolbar-row>
    <div class="col-3">
      <img
        src="../../../assets/images/header-logo.svg"
        width="90px"
        (click)="gotoLandingPage()"
        style="cursor: pointer"
        />
    </div>

    <!-- <span class="example-spacer"></span> -->
    <div class="col-9">
      <div class="row toolbar-options justify-content-end align-items-center">
        <!-- end of global search-->
        <!-- <span class="example-spacer"></span> -->
        <div class="col-6 d-flex align-items-center justify-content-center">
          @if ( adminType === adminTypes.CMO ) {
            <!-- global search-->
            <div class="searchFormHeader">
              <form [formGroup]="searchForm">
                <!-- {{booksName}} -->
                <!-- style="height: 25px; width: 400px; font-size: 0.875rem" -->
                <mat-form-field
                  appearance="outline"
                  subscriptSizing="dynamic"
                  style="width: 400px; font-size: 0.875rem"
                  >
                  <input
                    matInput
                    aria-label="State"
                    [matAutocomplete]="auto"
                    placeholder="{{ 'Search' | translate }}"
                    formControlName="searchBar"
                    />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="gotoPatientDetails($event.option.value)"
                    class="search-list-autocomplete"
                    >
                    @if (!isLoading && searchResult.length < 1) {
                      <mat-option
                        style="font-size: 0.875rem !important"
                        class="is-loading"
                        >No results found</mat-option
                        >
                      }
                      @if (!isLoading) {
                        @for (option of searchResult; track option) {
                          <mat-option
                            style="font-size: 0.75rem !important"
                            [value]="option"
                            >
                            <div class="search-details">
                              <span
                                class="search-list"
                                style="text-transform: capitalize"
                                >{{ option.firstName + " " + option.lastName }}</span
                                >
                                <span class="search-list">{{
                                  option.identificationNumber
                                }}</span>
                                @if (option.parent) {
                                  <span
                                    class="search-list"
                                    >{{
                                    option.parent.countryCode +
                                    "-" +
                                    option.parent.phoneNumber
                                    }}</span
                                    >
                                  } @else {
                                    {{ option.countryCode + "-" + option.phoneNumber }}
                                  }
                                </div>
                              </mat-option>
                            }
                          }
                        </mat-autocomplete>
                      </mat-form-field>
                    </form>
                  </div>
                }
              </div>

              <!-- [ngClass]="{'justify-content-end': adminType !== adminTypes.CMO}" -->
              <!-- [ngClass]="{'justify-content-evenly': adminType === adminTypes.CMO}" -->
              <div
                class="col-4 d-flex align-items-center"
          [class]="
            adminType === adminTypes.CMO
              ? 'justify-content-evenly'
              : 'justify-content-end'
          "
                >
                <!-- LISTA DE OPCIONES -->
                <!-- <div class="list-options"> -->
                @if ( ![adminTypes.CMO, adminTypes.MARKETING].includes(adminType) ) {

                  <button
                    class="button-list-actions"
                    [matMenuTriggerFor]="list"
                    aria-label="Example icon-button with a menu"
                    mat-button
                    >
                    <!-- mat-icon-button -->
                    <span class="text-button">
                      {{ "List" | translate }}
                    </span>
                  </button>

                }
                <mat-menu #list="matMenu">
                  @if (adminType === adminTypes.CEO) {
                    <button
                      mat-menu-item
                      (click)="routingToModule('/admin')"
                      >
                      <span>{{ "Admin List" | translate }}</span>
                    </button>
                  }

                  @if (adminType === adminTypes.CEO) {
                    <mat-divider></mat-divider>
                  }

                  <button mat-menu-item (click)="routingToModule('/agents')">
                    <span>{{ "Agent List" | translate }}</span>
                  </button>

                  <mat-divider></mat-divider>

                  @if (!isMarketingRole) {
                    <button
                      mat-menu-item
                      (click)="routingToModule('/patients')"
                      >
                      <span>{{ "Patient List" | translate }}</span>
                    </button>
                  }

                  @if (!isMarketingRole) {
                    <mat-divider></mat-divider>
                  }

                  @if (!isMarketingRole) {
                    <button
                      mat-menu-item
                      (click)="routingToModule('/doctor')"
                      >
                      <span>{{ "Doctor List" | translate }}</span>
                    </button>
                  }

                  @if (!isMarketingRole) {
                    <mat-divider></mat-divider>
                  }

                  @if (!isMarketingRole) {
                    <button
                      mat-menu-item
                      (click)="routingToModule('/org')"
                      >
                      <span>{{ "Organization List" | translate }}</span>
                    </button>
                  }

                  @if (!isMarketingRole) {
                    <mat-divider></mat-divider>
                  }

                  @if (!isMarketingRole) {
                    <button
                      mat-menu-item
                      (click)="routingToModule('/allies')"
                      >
                      <span>{{ "List of allies" | translate }}</span>
                    </button>
                  }

                  @if (!isMarketingRole) {
                    <mat-divider></mat-divider>
                  }

                  @if (!isMarketingRole) {
                    <button
                      mat-menu-item
                      (click)="routingToModule('/allConsults')"
                      >
                      <span>{{ "All Consults List" | translate }}</span>
                    </button>
                  }

                  @if (isMarketingRole) {
                    <mat-divider></mat-divider>
                  }

                  @if (isMarketingRole) {
                    <button
                      mat-menu-item
                      (click)="routingToModule('/digitalAssets')"
                      >
                      <span>{{
                        "Digital Assets Content.List Digital Assets" | translate
                      }}</span>
                    </button>
                  }
                </mat-menu>
              <!-- </div> -->
            <!-- </span> -->

            <!-- TÉRMINOS Y CONDICIONES -->
            @if ( ![adminTypes.CMO, adminTypes.MARKETING].includes(adminType) ) {
              <button mat-button (click)="termCondition()">
                <span class="toolbar-span">
                  <!-- *ngIf="adminType !== adminTypes.CMO" -->
                  {{ "TC & PP" | translate }}
                </span>
              </button>
            }

            <!-- REGISTRO DE ACTIVIDADES adminType === adminTypes.CMO -->
            @if ( adminType === adminTypes.CMO) {
              <button mat-button (click)="routingToModule('/logs')">
                <!-- padding-right: 35px; cursor: pointer -->
                <span class="toolbar-span">
                  {{ "Logs" | translate }}
                </span>
              </button>
            }

            <!-- LISTA DE TODAS LAS CONSULTAS  adminType === adminTypes.CMO-->
            @if ( adminType === adminTypes.CMO ) {
              <button mat-button (click)="routingToModule('/allConsults')">
                <!-- padding-right: 35px; cursor: pointer -->
                <span class="toolbar-span">
                  {{ "All Consults List" | translate }}
                </span>
              </button>
            }

            <!-- AVATAR -->
            <span class="toolbar-avatar">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                class="button-avatar"
                >
                <app-avatar [name]="userName">
                </app-avatar>
                <!-- <ngx-avatar [name]="userName" size="40"></ngx-avatar> -->
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="changePassword()">
                  <mat-icon color="warn"
                    ><img src="../../../assets/images/lock.svg"
                  /></mat-icon>
                  <span>{{ "Change Password" | translate }}</span>
                </button>
                <mat-divider></mat-divider>
                <!-- <button mat-menu-item (click)="changeLanguage('en-US')">
                <mat-icon>language</mat-icon>
                <span>English</span>
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="changeLanguage('es-ES')">
                <mat-icon>language</mat-icon>
                <span>Spanish</span>
              </button>
              <mat-divider></mat-divider> -->
              <button mat-menu-item (click)="logOut()">
                <mat-icon color="warn"
                  ><img src="../../../assets/images/power.svg"
                /></mat-icon>
                <span style="color: #cd252d">{{ "Logout" | translate }}</span>
              </button>
            </mat-menu>
          </span>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
