<div class="reset-container container-fluid">
  <app-header></app-header>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h4 class="heading">
          {{ "Set New Password" | translate }}
        </h4>

        @if (showError) {
          <p style="font-weight: bold; color: red">
            {{ errorMsg }}
          </p>
        }
      </div>

      <div class="col-12">
        <!-- <p class="subheading">Please enter your email below</p> -->
        @if (!hideForm) {
          <mat-card class="reset-card container">
            <mat-card-content>
              <form
                class="example-form"
                [formGroup]="changepasswordForm"
                (ngSubmit)="changePasswordSubmit()"
                >
                <p style="text-align: center; color: #46535b; font-weight: 600">
                  {{
                  "Please enter your new password and confirm password."
                  | translate
                  }}
                </p>
                <mat-form-field
                  class="example-full-width app-form-field"
                  style="width: 80%"
                  >
                  <input
                    [type]="passwordHide ? 'password' : 'text'"
                    matInput
                    placeholder="{{ 'New Password' | translate }}"
                    formControlName="password"
                    required
                    />
                  <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{
                    passwordHide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                  @if (
                    changepasswordForm.get('password').hasError('required')
                    ) {
                    <mat-error
                      >
                      {{ "Password is Required." | translate }}
                    </mat-error>
                  }
                  @if (changepasswordForm.get('password').hasError('pattern')) {
                    <mat-error
                      >
                      {{ "Please enter valid password." | translate }}
                    </mat-error>
                  }
                  @if (
                    changepasswordForm.get('password').hasError('minlength')
                    ) {
                    <mat-error
                      >
                      {{
                      "Password should be minimum of 8 characters." | translate
                      }}
                    </mat-error>
                  }
                </mat-form-field>
                <br /><br />
                <mat-form-field
                  class="example-full-width app-form-field"
                  style="width: 80%"
                  >
                  <input
                    [type]="confirmPasswordHide ? 'password' : 'text'"
                    matInput
                    placeholder="{{ 'Confirm Password' | translate }}"
                    formControlName="confirmPassword"
                    required
                    />
                  <mat-icon
                    matSuffix
                    (click)="confirmPasswordHide = !confirmPasswordHide"
                    >{{
                    confirmPasswordHide ? "visibility_off" : "visibility"
                    }}</mat-icon
                    >
                    @if (
                      changepasswordForm
                      .get('confirmPassword')
                      .hasError('required')
                      ) {
                      <mat-error
                        >
                        {{ "Password is Required." | translate }}
                      </mat-error>
                    }
                    @if (
                      changepasswordForm
                      .get('confirmPassword')
                      .hasError('mustMatch')
                      ) {
                      <mat-error
                        >
                        {{ "Password and confirm password do not match" | translate }}
                      </mat-error>
                    }
                  </mat-form-field>
                  <div class="reset-button-row">
                    <button [disabled]="loading" mat-raised-button>
                      {{ "Continue" | translate }}
                    </button>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
          }
        </div>
      </div>
    </div>
  </div>
