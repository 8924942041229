import { Component, Input } from "@angular/core";

const CICLE_PERCENTAGE = 89.735;

@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent {
  @Input() src: string;
  @Input() name: string;

  @Input() backgroundColor: string = "var(--app-red)";
  @Input() height: string = "40px";
  @Input() width: string = "40px";
  @Input() fontSize: string = "18px";

  get initials(): string {
    if (!this.name) return "";
    const initials = this.name[0]?.charAt(0).toUpperCase();
    return initials;
  }

  get cicleHeight(): string {
    return this.calculateCircleSize(this.height);
  }

  get cicleWidth(): string {
    return this.calculateCircleSize(this.width);
  }

  private calculateCircleSize(sizePX: string): string {
    const size = parseFloat(sizePX.replace("px", ""));
    const cicleSize = (CICLE_PERCENTAGE * size) / 100;
    return `${cicleSize}px`;
  }
}
