<div>
  <div class="containerterm">
    <p class="terms">{{ "Terms and condions & Privacy Policy" | translate }}</p>
    <br />
    <form [formGroup]="AgreementForm">
      <mat-tab-group class="termAndConditions" mat-align-tabs="center">
        <mat-tab labelClass="tabLabel" label="{{ 'Patients' | translate }}">
          <br />
          <div>
            @if (!editPatiensTerms) {
              <div class="TC">
                {{ "Terms and condions" | translate }}
                <span
                  class="savebutton"
                  (click)="termConditionPatient()"
                  style="cursor: pointer"
                  >
                  {{ "Save" | translate }}
                  <img class="btn-icon" src="../../../assets/images/save.svg" />
                </span>
              </div>
            }
            @if (editPatiensTerms) {
              <div class="TC">
                {{ "Terms and condions" | translate }}
                <span
                  class="savebutton"
                  (click)="editPatientTerms()"
                  style="cursor: pointer"
                  >{{ "Edit" | translate }}
                  <img class="btn-icon" src="../../../assets/images/edit.svg" />
                </span>
              </div>
            }
            <br />
            <quill-editor
              #editorTermPatient
              [(ngModel)]="editorText.termPatient"
              [style]="{ height: '250px' }"
              [modules]="editorModules"
              formControlName="termPatient"
              >
            </quill-editor>
          </div>
          <br /><br />
          <div>
            @if ( editPatientpolicy ) {
              <div class="TC">
                {{ "Privacy Policy" | translate }}
                <span
                  class="savebutton"
                  (click)="editPatientPolicy()"
                  style="cursor: pointer"
                  >{{ "Edit" | translate }}
                  <img class="btn-icon" src="../../../assets/images/edit.svg" />
                </span>
              </div>
            } @else {
              <div class="TC">
                {{ "Privacy Policy" | translate }}
                <span
                  class="savebutton"
                  (click)="privacyPolicyPatient()"
                  style="cursor: pointer"
                  >{{ "Save" | translate }}
                  <img class="btn-icon" src="../../../assets/images/save.svg" />
                </span>
              </div>
            }
            <br />
            <quill-editor
              #editorPolicyPatient
              [(ngModel)]="editorText.PolicyPatient"
              formControlName="policyPatient"
              [style]="{ height: '250px' }"
              [modules]="editorModules"
              >
            </quill-editor>
          </div>
        </mat-tab>

        <mat-tab labelClass="tabLabel" label="{{ 'Doctors' | translate }}">
          <br />
          <div>
            @if ( editterm ) {
              <div class="TC">
                {{ "Terms and condions" | translate }}
                <span
                  class="savebutton"
                  style="cursor: pointer"
                  (click)="editDoctorTerm()"
                  >{{ "Edit" | translate }}
                  <img class="btn-icon" src="../../../assets/images/edit.svg" />
                </span>
              </div>
            } @else {
              <div class="TC">
                {{ "Terms and condions" | translate
                }}<span
                class="savebutton"
                style="cursor: pointer"
                (click)="termConditionDoctor()"
                >{{ "Save" | translate }}
                <img class="btn-icon" src="../../../assets/images/save.svg" />
              </span>
            </div>
          }
          <br />
          <quill-editor
            #editorTermDoctor
            formControlName="termDoctor"
            [style]="{ height: '250px' }"
            [modules]="editorModules"
            >
          </quill-editor>
        </div>
        <br /><br />
        <div>
          @if ( editpolicy ) {
            <div class="TC">
              {{ "Privacy Policy" | translate }}
              <span
                class="savebutton"
                style="cursor: pointer"
                (click)="editDoctorPolicy()"
                >{{ "Edit" | translate }}
                <img class="btn-icon" src="../../../assets/images/edit.svg" />
              </span>
            </div>
          } @else {
            <div class="TC">
              {{ "Privacy Policy" | translate }}
              <span
                class="savebutton"
                style="cursor: pointer"
                (click)="privacyPolicyDoctor()"
                >
                {{ "Save" | translate }}
                <img class="btn-icon" src="../../../assets/images/save.svg" />
              </span>
            </div>
          }
          <br />
          <quill-editor
            #editorPolicyDoctor
            formControlName="policyDoctor"
            [style]="{ height: '250px' }"
            [modules]="editorModules"
            >
          </quill-editor>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
</div>
