<div class="avatar-border" [ngStyle]="{
  'border-color': backgroundColor,
  width,
  height
}"
>
  <div
    class="avatar"
    [ngStyle]="{
      'background-color': backgroundColor,
      width: cicleWidth,
      height: cicleHeight
    }"
  >
    @if (src) {
      <img [src]="src" alt="Avatar" />
    }
    @if (!src) {
      <span [ngStyle]="{ 'font-size': fontSize }">
        {{ initials }}
      </span>
    }
  </div>
</div>