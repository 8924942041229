import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  Router,
  CanActivateChild,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthorizationService } from "./authorization.service";
import { AdminType } from "../home/admin/admin.model";
import { CredentialsService } from "./credentials.service";

@Injectable({
  providedIn: "root",
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private authorizationService: AuthorizationService,
    private credentialService: CredentialsService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const allowedRoles = next.data.allowedRoles;
    const isAuthorized = this.authorizationService.isAuthorized(allowedRoles);

    if (!isAuthorized) {
      this.router.navigate(["/"]);
    }

    return isAuthorized;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const allowedRoles = next.data.allowedRoles;
    const isAuthorized = this.authorizationService.isAuthorized(allowedRoles);

    if (!isAuthorized) {
      if (this.credentialService.isAdminType(AdminType.MARKETING)) {
        this.router.navigate(["/digitalAssets"]);
      }
    }

    return isAuthorized;
  }
}
