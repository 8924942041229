import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MultipleErrorMessageComponent } from "./multiple-error-message/multiple-error-message.component";
import { ErrorMessageComponent } from "./error-message/error-message.component";
import { MessageComponent } from "../home/message/message.component";
import { FormGroup } from "@angular/forms";
import { DocumentExtension, DocumentImageReference } from "./types/enums.types";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) { }

  showMessage(messages: string, panelClass: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: messages,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
      panelClass: panelClass,
    });
  }

  showMultipleErrors(message: any, panelClass: string) {
    this.snackBar.openFromComponent(MultipleErrorMessageComponent, {
      data: message,
      panelClass: panelClass,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
    });
  }

  showErrorMessage(msg: string, panelClass: string = "single") {
    if (msg){
      this.snackBar.openFromComponent(ErrorMessageComponent, {
        data: msg,
        panelClass: panelClass,
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: 10000,
      });
    }
  }

  getMessages(error: any): any {
    let messages: string[] = [];

    if (error?.error?.message?.length && Array.isArray(error.error.message)) {
      messages = error.error.message.join(", ");
    } else {
      messages.push("Un error ha ocurrido");
    }

    return messages;
  }

  handleError(error, form?: FormGroup) {
    if (error.error instanceof ErrorEvent) {
      // client-side error
      const errorMessage = error.error.message;
      this.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error
      if (error.status === 400) {
        if (form) {
          error.error?.message?.forEach((message) => {
            const errors = Object.keys(message?.constraints);
            errors.forEach((error) => {
              const errorMessage: any = {
                message: `${message.constraints[error]}`,
              };
              errorMessage[error] = true;
              form?.get(message.property)
                .setErrors({ backError: errorMessage });
            });
          });
        } else {
          let errorMessage: string = "";
          error.error.message.map((message) => {
            const errors = Object.keys(message?.constraints);
            errors.forEach((error) => {
              errorMessage += `${message.constraints[error]}\n`;
            });
          });
          this.showErrorMessage(errorMessage);
        }
      } else if (error.status == 422) {
        let values = [];
        for (let key in error.error.message) {
          values.push(error.error.message[key]);
        }
        this.showMultipleErrors(values, "multi");
      } else if (error?.error || error?.error?.message) {
        const errorMessage = typeof error.error == "object" ?
          error.error.message[0] :
          error.error;
        this.showErrorMessage(errorMessage, "single");
      }
    }
  }

  getSrcDocument(data) {
    try {
      const url = new URL(data.url);
      const path = url.pathname;
      const imageExtension: string = path.split(".").pop();

      if (imageExtension.includes(DocumentExtension.PDF)) {
        return DocumentImageReference.PDF;
      }

      if (imageExtension.includes(DocumentExtension.DOCX) ||
        imageExtension.includes(DocumentExtension.DOC)
      ) {
        return DocumentImageReference.DOC;
      }

      if (
        imageExtension.includes(DocumentExtension.XLSX) ||
        imageExtension.includes(DocumentExtension.XLS) ||
        imageExtension.includes(DocumentExtension.CSV)
      ) {
        return DocumentImageReference.XLS;
      }

      return data.url;
    } catch (error) {
      return this.getSrcDocumentError();
    }
  }

  getSrcDocumentError() {
    return this.translateService.currentLang == "en-US" ?
      DocumentImageReference.ERROR_EN :
      DocumentImageReference.ERROR_ES;
  }

  removeEmptyKeys(obj) {
    const keys: Array<string> = new Array();
    for (const propName in obj) {
      if (!obj[propName] || obj[propName].length < 1) {
        keys.push(propName);
      }
    }
    return _.omit(obj, keys);
  }
}
