<div class="container-fluid forgot-container">
  <app-header></app-header>
  <div class="container">
    <div class="row">
      <div style="text-align: center">
        <h4 class="heading">{{ "Forgot Password" | translate }}</h4>
        <p class="subheading">
          {{ "Please enter your registered email below" | translate }}
        </p>
      </div>
    </div>

    <div class="row justify-content-center">
      <mat-card class="forgot-card col-10">
        <mat-card-content>
          <form
            class="example-form"
            [formGroup]="forgotpasswordForm"
            (ngSubmit)="forgotPasswordSubmit()"
            >
            <div class="row align-items-center justify-content-center">
              <p style="text-align: initial; color: #46535b; font-weight: 600">
                {{ "We will send you link to reset your password" | translate }}
              </p>
            </div>

            <div class="row">
              <mat-form-field class="example-full-width app-form-field">
                <mat-label>
                  {{ "Email" | translate }}
                </mat-label>
                <input
                  type="text"
                  matInput
                  autocomplete="email"
                  formControlName="email"
                  required
                  />
                @if (forgotpasswordForm.get('email').hasError('required')) {
                  <mat-error
                    >
                    {{ "Email is Required." | translate }}
                  </mat-error>
                }
                @if (forgotpasswordForm.get('email').hasError('pattern')) {
                  <mat-error
                    >
                    {{ "Please enter a valid email" | translate }}
                  </mat-error>
                }
              </mat-form-field>
              <!-- <p class="msg" *ngIf="showSuccessMsg">
              {{
              "The reset password link has been sent to your registered mail."
              | translate
              }}
            </p> -->
            @if (showErrorMsg) {
              <p style="color: red; font-weight: bold">
                {{ errorMsg | translate }}
              </p>
            }
          </div>

          <div class="row mt-4">
            <div class="forgot-button-row">
              <button
                mat-raised-button
                [disabled]="loading || showSuccessMsg"
                >
                {{ "Continue" | translate }}
              </button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
</div>
