<div class="flex">
  <div class="data">
    <img src="../../../assets/images/tick.svg" style="margin-right: 10px" />
    {{ data }}
  </div>
  <!-- <div class="dismiss">
        <button mat-icon-button (click)="snackBarRef.dismiss()">
            <mat-icon>close</mat-icon>
          </button>
      </div> -->
</div>
