import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CredentialsService } from "src/app/core/credentials.service";
import { AuthenticationService } from "src/app/core/authentication.service";
import { I18nService } from "src/app/core/i18n.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { SearchService } from "src/app/home/allconsult/search/search.service";
import { SharedService } from "../shared.service";
import { HttpErrorResponse } from "@angular/common/http";
import { AdminType } from "src/app/home/admin/admin.model";

@Component({
  selector: "app-home-header",
  templateUrl: "./home-header.component.html",
  styleUrls: ["./home-header.component.scss"],
})
export class HomeHeaderComponent implements OnInit {
  userName: string;
  adminType: number;
  searchForm: FormGroup;
  searchResult = [];
  booksName = [];
  isLoading: boolean = false;
  adminTypes = AdminType;
  isMarketingRole = false;

  constructor(
    private router: Router,
    private credService: CredentialsService,
    private authService: AuthenticationService,
    private i18nService: I18nService,
    private sharedService: SharedService,
    private searchService: SearchService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.userName =
      this.credService.credentials.userDetails.firstName +
      " " +
      this.credService.credentials.userDetails.lastName;

    const quantityRoles = 4;
    const activeRoles = Object.keys(this.adminTypes)
      .slice(0, quantityRoles)
      .map((role) => Number(role));
    this.adminType = this.credService.credentials.userDetails.adminType;

    this.isMarketingRole = this.adminTypes["MARKETING"] == this.adminType;

    this.searchForm = this.fb.group({
      searchBar: "",
    });
    this.onChanges();
  }

  gotoLandingPage() {
    if (this.credService.isAuthenticated()) {
      if (this.credService.isAdminType(AdminType.CMO)) {
        this.router.navigate(["/logs"]);
      } else {
        this.router.navigate(["/dashboard"]);
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }

  changePassword() {
    this.router.navigate(["/change-password"]);
  }

  termCondition() {
    this.router.navigate(["/agreements"]);
  }

  logOut() {
    this.authService.logout().subscribe(
      (res: any) => {
        this.router.navigate(["/login"]);
      },
      (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    );
  }

  routingToModule(routeValue: string) {
    this.router.navigate([routeValue]);
  }

  changeLanguage(lang: string) {
    this.i18nService.language = lang;
    window.location.reload();
  }

  onChanges() {
    this.isLoading = true;
    this.searchForm
      .get("searchBar")
      .valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((data: any) => {
        if (data.length > 2) {
          this.searchService
            .searchingValue({
              search: data,
              includeChild: true,
            })
            .subscribe(
              (res: any) => {
                this.isLoading = false;
                this.searchResult = res.data as Array<{}>;
              },
              (err: HttpErrorResponse) => {
                this.handleError(err);
              }
            );
        } else {
          this.isLoading = true;
        }
      });
  }

  displayFn(value?: any): string | undefined {
    // this.router.navigate(["/consults"], { queryParams: { id: value._id, patient: value._id } });

    return value ? value.firstName + " " + value.lastName : undefined;
  }

  gotoPatientDetails(value) {
    this.router.navigate(["/allConsults/search"], {
      queryParams: { patient: value._id },
    });
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
