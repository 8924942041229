export enum ProviderType {
    TELECARRIER = 1,
    BUSINESS = 2,
    BANK = 3,
    INSURANCE = 4,
}

export enum FamilyRelationship {
    FATHER = 1,
    MOTHER = 2,
    BROTHER = 3,
    SISTER = 4,
    SON = 5,
    DAUGHTER = 6,
}

export enum DocumentExtension {
    PNG = 'png',
    JPG = 'jpg',
    PDF = 'pdf',
    DOC = 'doc',
    DOCX = 'docx',
    XLS = 'xls',
    XLSX = 'xlsx',
    CSV = 'csv'
}

export enum DocumentImageReference {
    PDF = '../../../assets/images/pdf-view.svg',
    DOC = '../../../assets/images/word.svg',
    XLS = '../../../assets/images/excel.svg',
    ERROR_EN = '../../../assets/images/no-photos-en.svg',
    ERROR_ES = '../../../assets/images/no-photos-es.svg',
}

export enum ModalConfirmActions {
    ACCEPT = "accept",
    DENY = "deny",
    CANCEL = "cancel",
}